@import url('../assets/fonts/fonts.scss');
@import './theme';

* {
  box-sizing: border-box;
}

body {
  height: unset !important;
  font-family: "Roboto", 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.root {
  position: relative;
  width: 100%;
}

html #chat-widget {
  z-index: 1003 !important;

  .open-chat-widget-button-container .open-chat-widget-button {
    background-image: url("../assets/icons/icon-chat.svg");
    background-size: 50%;
    width: 40px;
    height: 40px;
  }
}
