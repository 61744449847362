@import "../../styles/theme";

.rootBreadCrumbsWrapper {
  visibility: visible;
}

.breadCrumbsWrapper {
  @extend %fontRoboto;

  display: flex;
  align-items: flex-start;
  white-space: nowrap;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $santasGrey;

  & > a {
    color: $cornflowerBlue;
    cursor: pointer;
    text-decoration: none;
  }
}

.disabled {
  cursor: default;
}

.buttonBreadCrumbs {
  cursor: pointer;
}

.breadCrumbsLinksShow {
  visibility: visible;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.breadCrumbsLinksHide {
  visibility: hidden;
  position: absolute;
}

.linkText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: $cornflowerBlue;
}

.ordinaryLink {
  color: $cornflowerBlue;
}
