@import "../../styles/theme";

.banner {
  background-color: $dodgerBlue;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1010;
  height: fit-content;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.text {
  margin: auto 0;

  @include setFont($size: 16px, $lineHeight: 24px, $weight: normal, $color: $white);

  p {
    margin: 0;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: $whiteSnow;
    }
  }
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 35%;
  right: 22px;

  &:active {
    opacity: 0.6;
  }

  @media (max-width: $break-768) {
    top: 20%;
  }
}

@media screen and (max-width: $break-768) {
  .banner {
    position: absolute;
    z-index: 1;
    top: $headerHeightMobile;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 112px;
    justify-content: center;
  }
}
