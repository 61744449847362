@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold'), local('Inter-SemiBold'), url('Inter-SemiBold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'), url('Inter-Medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'), url('Inter-MediumItalic.otf') format('otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'), url('Inter-Italic.otf') format('otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraLight'), local('Inter-ExtraLight'), url('Inter-ExtraLight-BETA.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light Italic'), local('Inter-LightItalic'), url('Inter-LightItalic-BETA.otf') format('otf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'), url('Inter-Light-BETA.otf') format('otf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Book'), local('Inter-Book'), url('Inter-Thin-BETA.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'), url('Inter-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Heavy'), local('Inter-Heavy'), url('Inter-ExtraBold.otf') format('otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter SemiBold Italic'), local('Inter-SemiBoldItalic'), url('Inter-SemiBoldItalic.otf') format('otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'), url('Inter-BoldItalic.otf') format('otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraLight Italic'), local('Inter-ExtraLightItalic'), url('Inter-ExtraLightItalic-BETA.otf') format('otf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'), url('Inter-Bold.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Heavy Italic'), local('FiraSans-HeavyItalic'), url('Inter-Italic.otf') format('otf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold'), local('Inter-ExtraBold'), url('Inter-ExtraBold.otf') format('otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Book Italic'), local('Inter-BookItalic'), url('Inter-ExtraLightItalic-BETA.otf') format('otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter ExtraBold Italic'), local('Inter-ExtraBoldItalic'), url('Inter-ExtraBoldItalic.otf') format('otf');
  font-weight: 800;
  font-style: italic;
}
