@import "../../styles/theme";

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div[class*=PageContainer_actionBlock] {
    align-self: center;
  }
}

.panelContainer {
  width: 100%;
  max-width: 962px;
  border: 1px solid $botticelli;
  border-radius: 32px;
  overflow: hidden;
  box-shadow: none;
  margin-bottom: 0;

  & > div[class*=panelBody] > main {
    padding: 44px 24px;
  }

  & > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    padding: 24px;
    background-color: $selagoLight;

    & > div[class*=badgeRoleWrapper] {
      background: transparent;
      font-size: 20px;
      line-height: 14px;
    }

    & > div > span {
      color: $dark-text;
      font-size: 20px;
      line-height: 24px;
      margin: 0;

      &.reject {
        color: $error-text;
      }

      &.confirmed {
        color: $green_01;
      }
    }
  }
}

@include from-to($break-768, 850px) {
  .panelContainer > div:first-of-type {
    padding: 15px;
  }
}

@include to(550px) {
  .panelContainer > div:first-of-type {
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.button {
  max-width: 368px;
  align-self: center;
  margin: auto 0;
}

.instructionButton {
  padding: 24px 24px 0 24px;
}

.contectWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contactWrapper {
  max-width: 800px;
  box-sizing: content-box;

  .phone {
    padding: 24px;

    .number {
      font-size: 20px;
    }

    a {
      text-decoration: none;
      color: $dodgerBlue;
      cursor: pointer;
    }
  }

  .mail {
    padding: 0 24px 0 24px;

    .address {
      font-size: 20px;
    }

    a {
      text-decoration: none;
      color: $dodgerBlue;
      cursor: pointer;
    }
  }
}

@include to(550px) {
  .contactWrapper {
    padding: 0;

    .phone {
      margin: 0 70px 20px 0;
    }

    .mail {
      margin: 0 70px 20px 0;
    }
  }
}
