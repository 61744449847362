@import '../../styles/theme';

.wrapper {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.red {
  background-color: #e73d3d;
}

.green {
  background-color: #2cd347;
}

.blue {
  background-color: #6899f7;
}

.orange {
  background-color: #e7a82e;
}
