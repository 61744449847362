.addButtonWrapper {
  display: flex;

  button {
    padding: 0;
    letter-spacing: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: transparent !important;
  }
}

.addIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
