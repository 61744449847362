@import "../../styles/theme";

.errorWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerContainer {
  width: 100%;
  max-width: fit-content;
}

.errorBlock {
  width: 100%;
  height: fit-content;
  padding: 21px 24px 28px;
  min-height: 120px;
  background-color: $white;
  border: 1px solid $error-text;
  box-sizing: border-box;
  box-shadow: 0 4px 16px $boxShadowColor;
  border-radius: 8px;

  & > :nth-child(n):not(:nth-last-child(1)) {
    margin-bottom: 15px;
  }
}

.titleError {
  font-size: 16px;
}

.infoError {
  @include setFont($size: 12px, $color: $dark);

  max-width: 498px;
}
