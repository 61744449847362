@import "../../styles/theme";

.badgeRoleWrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 129px;
  height: 40px;
  border-radius: 24px;
  color: $white;
  background-color: $lightGray;
  padding: 0 17px;
  font-size: 12px;
  line-height: 16px;
}

.badge {
  background-color: $selagoLight;
  color: $jumbo;
}
