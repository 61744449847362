@import "../../styles/theme";

.headerTypeDocument {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 12px;
  padding-top: 7px;
}

.headerTypeDocument:hover {
  color: $blue;
  cursor: pointer;
}

.constituentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  min-height: 160px;
  justify-content: space-between;
}

.button {
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: $primary-text;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
}

.parametersDocumentWrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.dateLicenseWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.parametersDocument {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.documentData {
  font-size: 12px;
  line-height: 16px;
  color: $primary-text;
}

.issuingAuthorityTitle {
  font-size: 12px;
  line-height: 16px;
  color: $primary-text;
  padding: 16px 0 8px 0;
}

.issuingAuthority {
  font-size: 12px;
  line-height: 16px;
  color: $dark-text;
}

.descriptionTitle {
  font-size: 12px;
  line-height: 16px;
  color: $primary-text;
  padding-bottom: 8px;
}

.documentFileWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
}

.documentFilesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 311px;
}

.documentRightWrapper {
  width: calc(40% - 55px);
  margin-left: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.documentFile {
  background-color: #f6f7f8;
  border-radius: 8px;
  padding: 19px 28px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.nameFile {
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 62%;
  white-space: nowrap;
  text-align: left;
  padding-left: 8px;
}

.sizeFile {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: right;
  color: $dark-text;
  padding-left: 18px;
}

.separator {
  padding: 19px 0 26px 0;
  width: 100%;
}

.trash {
  margin: auto 12px 0 0;
  cursor: pointer;

  &:active {
    opacity: 0.4;
  }
}

.licenceStatus {
  font-size: 12px;
  line-height: 16px;
}

.licenceStatusError {
  color: #f90;
}

.licenceStatusGoToEnd {
  color: $error-text;
}

.isExpired {
  position: absolute;
  width: 16px;
  height: 16px;
  right: -7px;
  top: -7px;
  background-color: $badge-background;
  border-radius: 50%;
}

.addButtonWrapper {
  margin-right: 12px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;
}

.descriptionTrashWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.spanText {
  /* need for IE */
  width: 100%;
}

.muted {
  color: $muted-text-color;
}

.formWrapper {
  margin: 53px 0;
}

.disabled {
  color: $primary-text;
}
