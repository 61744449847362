@import "../../styles/theme";

.title {
  @extend %fontRoboto;

  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: $black;
  margin: 0 0 23px 0;
  white-space: pre-line;
}
