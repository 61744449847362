@import "../../styles/theme";

.wrapper {
  margin-top: 30px;
}

.bar {
  width: 100%;
  height: 10px;
  margin-bottom: 5px;
  background-image: linear-gradient(to right, $blue-normal 50%, $blue-disabled 50%);
  background-size: 200% 100%;
  background-position: 100% 0%;
  transition: background-position 100ms;
}

.barText {
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
