@import "../../styles/theme";

.button {
  font-family: 'FiraSans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  color: $blueNormal;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  background-color: transparent;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 0;
  }

  &:hover {
    color: $blueNormal;
  }

  &.disabled {
    color: $blueDisabled;
  }
}

.buttonActive {
  font-weight: 500;
  color: $blueNormal;
}
