@import "../../styles/theme";

.innerContainer {
  width: 100%;
  max-width: fit-content;
}

.errorBlock {
  width: 100%;
  padding: 21px 24px 28px;
  min-height: 120px;
  background-color: $white;
  border: 1px solid $error-text;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 32px;
}

.titleError {
  font-size: 16px;
  margin-bottom: 15px;
}

.infoError {
  @include setFont($size: 12px, $color: $dark);

  max-width: 498px;
}
