@import "../../styles/theme";

.cardPanelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $boxShadowBase;
  width: 100%;
  box-sizing: border-box;
  background: $white;
  border: 1px solid $light;
  height: inherit;
}
