@import '../../styles/theme.scss';

.raitingAchievements {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.raitingWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  & > :nth-child(n):not(:nth-last-child(1)) {
    margin-bottom: 24px;
  }
}

.raitingItemWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  & > :nth-child(n):not(:nth-last-child(1)) {
    margin-bottom: 24px;
  }
}

.firstRatingWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;

  &:nth-last-child(1) {
    min-width: 326px;
  }
}

.dropdownWrapper {
  width: 100%;
  max-width: 492px;
  margin-bottom: 8px;

  & > div {
    & > div[class*=wrapper] {
      &:hover {
        border: 1px solid $dodgerBlue !important;
      }
    }
  }
}

.raitingBlock {
  display: flex;
  flex-direction: column;
}

.achievementsBlock {
  & > span {
    font-size: 14px;
  }

  position: absolute;
  left: 293px;
  top: 87px;
  display: flex;
  flex-direction: column;
}

.raitingCount {
  &.default {
    font-size: 32px;
    line-height: 36px;
    margin-right: 16px;
    font-weight: 700;
    min-width: 65px;
  }

  &.small {
    font-size: 16px;
    line-height: 19px;
    margin-right: 8px;
    font-weight: 400;
    min-width: 32px;
  }
}

.raitingStars {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.default {
    margin-bottom: 16px;
    margin-right: 16px;
  }

  &.small {
    margin-bottom: 8px;
    margin-right: 8px;
  }
}

.raitingDescription {
  color: $light-grey;

  &.default {
    font-size: 16px;
    line-height: 20px;
  }

  &.small {
    font-size: 14px;
    line-height: 20px;
  }
}

.toggleButton {
  height: 20px;
  display: flex;
  align-items: center;

  & > svg {
    margin-left: 8px;
    transition: transform 300ms;
  }

  &.open {
    & > svg {
      transform: rotateX(180deg);
    }
  }
}

.inline {
  & > div[class*=raitingItemWrapper] {
    flex-direction: row;
    column-gap: 24px;
    flex-wrap: wrap;

    & > div {
      width: fit-content;
      min-width: 268px;
    }
  }
}

.showMore {
  max-width: 228px;
  color: $dodgerBlue !important;
}

.seconRaitingWrapper {
  height: max-content;
  overflow: hidden;
  transition: max-height 300ms;

  &.open {
    max-height: 300px;
  }

  &.closed {
    max-height: 0;
  }
}
