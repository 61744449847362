@import "../../styles/theme";

.openPanelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: $boxShadowBase;
  border: 1px solid $light;
  width: 800px;
  box-sizing: border-box;
}

.panelHeader {
  padding: 0 56px 0 72px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 104px;
  align-items: center;
  background-color: $disabled;
}

.panelBody {
  width: 100%;
  background-color: $white;
}

.roleWrapper {
  color: $white;
  background-color: $lightGray;
}
