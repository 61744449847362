@import "../../../../styles/theme";

.container {
  background-color: $white;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 47px 32px 40px 32px;
  width: 100%;
  max-width: 936px;
  box-sizing: border-box;
}

.dropdownWrapper {
  margin-top: 20px;
  margin-bottom: 55px;
  width: 100%;
}
