// colors
// new pallet schema
$white:           #fff;
$whiteSnow:       #faf9fa;
$black:           #000;
$denim:           #1467b3;
$azure:           #326fb9;
$dodgerBlue:      #1890ff;
$cornflowerBlue:  #6177ff;
$wildBlueYonder:  #7f8ebb;
$botticelli:      #d5dceb;
$wildSand:        #f5f5f5;
$alabaster:       #f9f9f9;
$zircon:          #f8faff;
$silver:          #bfbfbf;
$lightGrey:       #fafafa;
$neutralGrey:     #a09fa1;
$selagoLight:     #f2f5fe;
$irishGreen:      #52c41a;
$darkMandarin:    #faad14;
$crayola:         #ff4d4f;
$gray:            #828080;
$jumbo:           #7a7a7c;
$error:           #db4444;
$cinnabar:        #e13c39;
$lavanda:         #e7e6e9;
$scorpion:        #595959;
$mercury:         #e8e8e8;
$solidRed:        #f00;
$torchRed:        #f5212d;
$alto:            #d9d9d9;
$blueBell:        #8384ca;
$selago:          #f3f5fe;
$moodyBlue:       #6d7bd5;
$athensGrey:      #eceef3;
$jordyBlue:       #99a7f9;
$santasGrey:      #a4a4a7;
$indigoCryola:    #5466d2;
$dodgerBlueHover: #40a9ff;
$irishGreenHover: #73d13d;
$darkMandarinHover: #fb3;

// old pallet schema
$blue:            #326fb9;
$error:           #db4444;
$muted-color:     #e3e1f3;
$light: #eee;
$dark-grey: #7a7a7c;
$light-grey: #9c9d9e;
$disabled: #f9f9f9;

$drop-shadow: 0 3px 30px rgba(46, 61, 121, 0.28);
$boxShadowBase: 0 0 24px rgba(213, 220, 235, 0.3);
$textLabelColor:   #999;
$primary-text:     #656289;
$error-text:       #db4444;
$muted-text-color: #a2a1bb;

$fontSizeButton:  16px;

// main theme
$main: #0a2750;
$third: #a9c9e7;
$accent: #3f75ff;
$background: #f7f7fb;
$dark-grey: #7a7a7c;
$dark: #15122f;

$blue-normal: #6177ff;
$blue-hover: #788bfb;
$blue-disabled: #d5dceb;
$blue-light: #f2f5fe;
$dark-blue: #2c386b;
$secondary: #3579b8;

$menuWidth: 240px;

//adaptive mixins
$break-1024: 1024px;
$break-1200: 1200px;
$break-900: 900px;
$break-768: 768px;
$break-600: 600px;

$boxShadowColor: rgba(32, 28, 77, 0.16);

//border radius
$borderRadiusBig: 32px;

$green_01: #349c6a;
$box-shadow: 0 0 24px rgba(213, 220, 235, 0.3);

@mixin from($min_width) {
  @media screen and (min-width: $min_width) {
    @content;
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

@mixin from-to($min_width, $max_width) {
  @media screen and (min-width: $min_width) and (max-width: $max_width) {
    @content;
  }
}

@mixin setFont($size: 16px, $lineHeight: 24px, $weight: normal, $color: $dark-grey) {

  font: {

    size: $size;
    weight: $weight;
  }

  line-height: $lineHeight;
  color: $color;
}

// fonts
%font {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
}

%fontRoboto {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
}

%fontRobotoBold {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

// для показа from
// theme colors
$brand-color: #56bdff;
$muted-color: #e3e1f3;
$background-color: #f7f7fb;
$badge-background: #eb5757;
$dark: #15122f;
$primary: #656289;
$white: #fff;
$blue: #3f75ff;
$blue-active: #233de0;
$lightGray: #9c9d9e;
$disabled: #f9f9f9;
$darkGray: #7a7a7c;
$light: #eee;
$blueNormal: #6177ff;
$blueExtraLight: #f8faff;
$boxShadowBase: 0 0 24px rgba(213, 220, 235, 0.3);

// text colors
$muted-text-color: #a2a1bb;
$dark-text: #15122f;
$primary-text: #656289;
$error-text: #db4444;
$dark-error-text: #da453f;
$black-text: #000;
$link: #3969e6;
$darkGray: #7a7a7c;
$lightGray: #9c9d9e;
$skyBlue: #f2f5fe;
$blueNormal: #6177ff;
$blueDark: #2c386b;
$blueDisabled: #d5dceb;
$blueHover: #788bfb;
$blueLight: #dce8ff;
$orange: #ff8515;

// main theme
$main: #0a2750;
$secondary: #3579b8;
$third: #a9c9e7;
$fourth: #dff0ff;
$background: #f7f7fb;
$error: #eb5757;
$accent: #3f75ff;

$grey: #828282;
$whiteGrey: #eee;
$secondaryAccent: #3579b8;
$greyLight: #f9f9f9;

// Menu / Header
$menuWidth: 240px;
$leftContentMargin: 80px;
$headerHeight: 76px;
$leftMenuHeight: 841px;
$headerHeightMobile: 64px;
$bannerHeaderHeight: 56px;
$red: #db4444;

// Footer
$heightFooter: 449px;
$heightFooterSecondary: 567px;

// Borders
$border-radius-32: 32px;

// TODO: colors for hard code (services cards)
// card theme (main)
$backgroundCard: #f7fbff;
$backgroundShadedCard: #f6fbff;

// table theme
$backgroundTableHeader: #f8faff;

// Company page
$maxWidthContainerCompany: 904px;

$boxShadowColor: rgba(32, 28, 77, 0.16);

// fonts

%shadowContainer {
  box-shadow: $boxShadowBase;
  border: 1px solid $whiteGrey;
}

// mixins
@mixin setFont($size: 12px, $lineHeight: 16px, $weight: normal, $color: $darkGray) {
  font: {
    size: $size;
    weight: $weight;
  }

  line-height: $lineHeight;
  color: $color;
}

@mixin borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border($width: 1px, $color: $blueDisabled) {
  border: $width solid $color;
}

//adaptive mixins
$break-1980: 1980px;
$break-1760: 1760px;
$break-1583: 1583px;
$break-1440: 1440px;
$break-1400: 1400px;
$break-1300: 1300px;
$break-1200: 1200px;
$break-1280: 1280px;
$break-1024: 1024px;
$break-1000: 1000px;
$break-997: 997px;
$break-996: 996px;
$break-992: 992px;
$break-970: 970px;
$break-901: 901px;
$break-900: 900px;
$break-960: 960px;
$break-768: 768px;
$break-668: 668px;
$break-667: 667px;
$break-600: 600px;
$break-450: 450px;
$break-449: 449px;
$break-430: 430px;
$break_iphone-375: 375px;
$break_android-360: 360px;

@mixin from($min_width) {
  @media screen and (min-width: $min_width) {
    @content;
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

@mixin from-to($min_width, $max_width) {
  @media screen and (min-width: $min_width) and (max-width: $max_width) {
    @content;
  }
}

// buttons
$sizeMobileBtn: 56px;

// theme colors
$brand-color: #56bdff;
$muted-color: #e3e1f3;
$background-color: #f7f7fb;
$badge-background: #eb5757;
$dark: #15122f;
$primary: #656289;
$white: #fff;
$blue: #3f75ff;
$blue-active: #233de0;
$lightGray: #9c9d9e;
$disabled: #f9f9f9;
$darkGray: #7a7a7c;
$light: #eee;
$blueNormal: #6177ff;
$blueExtraLight: #f8faff;
$boxShadowBase: 0 0 24px rgba(213, 220, 235, 0.3);

// text colors
$muted-text-color: #a2a1bb;
$dark-text: #15122f;
$primary-text: #656289;
$error-text: #db4444;
$dark-error-text: #da453f;
$black-text: #000;
$link: #3969e6;
$darkGray: #7a7a7c;
$lightGray: #9c9d9e;
$skyBlue: #f2f5fe;
$blueNormal: #6177ff;
$blueDark: #2c386b;
$blueDisabled: #d5dceb;
$blueHover: #788bfb;
$blueLight: #dce8ff;
$orange: #ff8515;

// main theme
$main: #0a2750;
$secondary: #3579b8;
$third: #a9c9e7;
$fourth: #dff0ff;
$background: #f7f7fb;
$error: #eb5757;
$accent: #3f75ff;

$grey: #828282;
$whiteGrey: #eee;
$secondaryAccent: #3579b8;
$greyLight: #f9f9f9;

// Menu / Header
$menuWidth: 240px;
$leftContentMargin: 80px;
$headerHeight: 76px;
$leftMenuHeight: 841px;
$headerHeightMobile: 64px;
$bannerHeaderHeight: 56px;
$red: #db4444;

// Footer
$heightFooter: 449px;
$heightFooterSecondary: 567px;

// Borders
$border-radius-32: 32px;

// TODO: colors for hard code (services cards)
// card theme (main)
$backgroundCard: #f7fbff;
$backgroundShadedCard: #f6fbff;

// table theme
$backgroundTableHeader: #f8faff;

// Company page
$maxWidthContainerCompany: 904px;

$boxShadowColor: rgba(32, 28, 77, 0.16);

// mixins
@mixin setFont($size: 12px, $lineHeight: 16px, $weight: normal, $color: $darkGray) {
  font: {
    size: $size;
    weight: $weight;
  }

  line-height: $lineHeight;
  color: $color;
}

@mixin borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin border($width: 1px, $color: $blueDisabled) {
  border: $width solid $color;
}

//adaptive mixins
$break-1980: 1980px;
$break-1760: 1760px;
$break-1583: 1583px;
$break-1440: 1440px;
$break-1400: 1400px;
$break-1300: 1300px;
$break-1200: 1200px;
$break-1280: 1280px;
$break-1024: 1024px;
$break-1000: 1000px;
$break-997: 997px;
$break-996: 996px;
$break-992: 992px;
$break-970: 970px;
$break-901: 901px;
$break-900: 900px;
$break-960: 960px;
$break-768: 768px;
$break-668: 668px;
$break-667: 667px;
$break-600: 600px;
$break-450: 450px;
$break-449: 449px;
$break-430: 430px;
$break_iphone-375: 375px;
$break_android-360: 360px;

@mixin from($min_width) {
  @media screen and (min-width: $min_width) {
    @content;
  }
}

@mixin to($max_width) {
  @media screen and (max-width: $max_width) {
    @content;
  }
}

@mixin from-to($min_width, $max_width) {
  @media screen and (min-width: $min_width) and (max-width: $max_width) {
    @content;
  }
}

// buttons
$sizeMobileBtn: 56px;
// для показа to
