@import "../../styles/theme";

.container {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  background-color: $white;
  min-height: 100%;
  transition: 0.3s;
  max-width: 1440px;
  width: 100%;

  &.mobile {
    align-items: center;
    max-width: none;
  }
}

.innerContainer {
  width: 100%;
}

.errorBlock {
  width: 100%;
  padding: 21px 24px 28px;
  min-height: 120px;
  background-color: $white;
  border: 1px solid $error-text;
  box-sizing: border-box;
  box-shadow: 0 4px 16px $boxShadowColor;
  border-radius: 8px;
}

.titleError {
  font-size: 16px;
  margin-bottom: 15px;
}

.infoError {
  @include setFont($size: 12px, $color: $dark);

  max-width: 498px;
}

@media screen and (max-width: 1366px) {
  .container {
    // margin-left: 72px;
    transition: margin-left ease 0.3s;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin-left: 0 !important;
  }
}
