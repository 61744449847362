@import "../../styles/theme.scss";

.modalBackground {
  position: fixed;
  right: 0;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: rgba($dark-blue, 0.5);
  z-index: 1100;

  .leftMenu {
    width: calc(100% - #{$menuWidth});
  }

  @include to($break-768) {
    width: 100%;
  }
}

.modalWrapper {
  position: fixed;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  top: 50%;
  left: calc(50% + 15px);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-width: 800px;
  min-width: 312px;
  width: 100%;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 32px;
  z-index: 1200;

  .leftMenu {
    transform: translate(-35%, -50%);
  }

  @include to($break-1200) {
    width: 50%;
  }

  @include to($break-768) {
    transform: translate(-50%, -50%);
  }
}

.closeButton {
  background: none;
  position: absolute;
  display: flex;
  right: 38px;
  top: 38px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  z-index: 100;
}

.modalContent {
  width: 100%;
}
