@import '../../styles/theme.scss';

.container {
  display: flex;
  justify-content: center;

  .wrapper {
    display: inline-flex;
  }

  .content {
    display: flex;
    align-items: center;
    margin: 0;
    list-style: none;
  }

  .controlButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    padding: 0;
    border-radius: 50%;
    background-color: $blueDisabled;
    outline: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    svg path {
      fill: none;
    }
  }

  .nextBtn {
    padding-left: 4px;
  }

  .prevBtn {
    padding-right: 4px;
  }

  .item {
    margin: 0 16px;
    padding: 0 !important;
    font-size: 12px;
    line-height: 16px;

    &::before {
      display: none;
    }
  }

  .itemButton {
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &.active {
      font-weight: 600;
    }
  }
}
